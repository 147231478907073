// TODO - Do we really need this file? It is literally just text values
//  that could just as easily be used directly

// Local Permission
const READ = 'READ';
const WRITE = 'WRITE';
const DELETE = 'DELETE';

// Local Access Levels
const ALL = 'ALL';
const OWN = 'OWN';
const INT = 'INT';
const SPUR = 'SPUR';
const MED = 'MED';
const RAWO = 'RAWO';
const LIMITED = 'LIMITED';

// Local Securable Entities
const Agent = 'Agent';
const AgentAttachment = 'AgentAttachment';
const AgentJournal = 'AgentJournal';
const Analytics = 'Analytics';
const AnalyticsGroup = 'AnalyticsGroup';
const aLocs = 'aLocs';
const aRoles = 'aRoles';
const Clusters = 'Clusters';
const DraftModel = 'DraftModel';
const PerformanceValue = 'PerformanceValue';
const Rock = 'Rock';
const Coach = 'Coach';
const CoachAttachment = 'CoachAttachment';
const CoachHistory = 'CoachHistory';
const CoachIntel = 'CoachIntel';
const CoachJournal = 'CoachJournal';
const CommentaryEvent = 'CommentaryEvent';
const CommentaryScoutSummary = 'CommentaryScoutSummary';
const CommentaryTrackerReport = 'CommentaryTrackerReport';
const Contact = 'Contact';
const ContractDashboard = 'ContractDashboard';
const Cyclops = 'Cyclops';
const Discussion = 'Discussion';
const Document = 'Document';
const FaxbackReport = 'FaxbackReport';
const FileByOtherScouts = 'FileByOtherScouts';
const FourFactorReport = 'FourFactorReport';
const Game = 'Game';
const IntelTrackerReport = 'IntelTrackerReport';
const List = 'List';
const ListCategory = 'ListCategory';
const ListRolesReport = 'ListRolesReport';
const LookupDiscussionMedium = 'LookupDiscussionMedium';
const LookupDiscussionType = 'LookupDiscussionType';
const LookupDraftRange = 'LookupDraftRange';
const LookupEventType = 'LookupEventType';
const LookupIntelCategory = 'LookupIntelCategory';
const LookupIntelMedium = 'LookupIntelMedium';
const LookupIntelRelationship = 'LookupIntelRelationship';
const LookupIntelSubject = 'LookupIntelSubject';
const LookupJournalMedium = 'LookupJournalMedium';
const LookupLeague = 'LookupLeague';
const LookupLocs = 'LookupLocs';
const LookupMediaMedium = 'LookupMediaMedium';
const LookupMedium = 'LookupMedium';
const LookupPlayerDesignation = 'LookupPlayerDesignation';
const LookupRelationship = 'LookupRelationship';
const LookupReportMedium = 'LookupReportMedium';
const MergePlayer = 'MergePlayer';
const MinPerReport = 'MinPerReport';
const Notifications = 'Notifications';
const Player = 'Player';
const PlayerAnalyticalAssessment = 'PlayerAnalyticalAssessment';
const PlayerAthleticTest = 'PlayerAthleticTest';
const PlayerAttachment = 'PlayerAttachment';
const PlayerBlotter = 'PlayerBlotter';
const PlayerDna = 'PlayerDna';
const PlayerHundred = 'PlayerHundred';
const PlayerInjury = 'PlayerInjury';
const PlayerIntel = 'PlayerIntel';
const PlayerJournal = 'PlayerJournal';
const PlayerMedia = 'PlayerMedia';
const PlayerRolesReport = 'PlayerRolesReport';
const PlayerSalary = 'PlayerSalary';
const PlayerScouting = 'PlayerScouting';
const PlayerShot = 'PlayerShot';
const PlayerStats = 'PlayerStats';
const PlayerSummary = 'PlayerSummary';
const PostgameReport = 'PostgameReport';
const RoomProjections = 'RoomProjections';
const Roster = 'Roster';
const RosterProjection = 'RosterProjection';
const SalaryBook = 'SalaryBook';
const Scout = 'Scout';
const ScoutingEvent = 'ScoutingEvent';
const ScoutingReportSummary = 'ScoutingReportSummary';
const ScoutIntelReport = 'ScoutIntelReport';
const ScoutTrackerReport = 'ScoutTrackerReport';
const SeenByScoutReport = 'SeenByScoutReport';
const Team = 'Team';
const TeamAsset = 'TeamAsset';
const TeamAttachment = 'TeamAttachment';
const TeamIntel = 'TeamIntel';
const TeamMedia = 'TeamMedia';
const TeamStanding = 'TeamStanding';
const ThreeYearProjections = 'ThreeYearProjections';
const UserAdmin = 'UserAdmin';
const UserProfile = 'UserProfile';
const VisualScenarios = 'VisualScenarios';

// Commentary by Type Collections
const AttachmentCommentary = [
  AgentAttachment,
  CoachAttachment,
  PlayerAttachment,
  TeamAttachment
];

const IntelCommentary = [
  CoachIntel,
  PlayerIntel,
  TeamIntel
];

const JournalCommentary = [
  AgentJournal,
  CoachJournal,
  PlayerJournal
];

const MediaCommentary = [
  PlayerMedia,
  TeamMedia
];

// Commentary by Personnel Collections
const AgentCommentary = [
  AgentAttachment,
  AgentJournal,
  Discussion
];

const CoachCommentary = [
  CoachAttachment,
  CoachIntel,
  CoachJournal,
  Discussion
];

const PlayerCommentary = [
  CommentaryEvent,
  Discussion,
  PlayerAttachment,
  PlayerBlotter,
  PlayerHundred,
  PlayerIntel,
  PlayerJournal,
  PlayerMedia,
  PlayerScouting
];

const TeamCommentary = [
  Discussion,
  TeamAttachment,
  TeamIntel,
  TeamMedia
];

// Medical/Commentary All Securable Collection
const AllMedical = [
  PlayerAthleticTest,
  PlayerInjury
];

const AllScouting = [
  ScoutingEvent
];

const AllContract = [
  ContractDashboard,
  RoomProjections,
  RosterProjection,
  SalaryBook,
  ThreeYearProjections,
  VisualScenarios
];

const AllCommentary = [
  AgentAttachment,
  AgentJournal,
  CoachAttachment,
  CoachIntel,
  CoachJournal,
  CommentaryEvent,
  Discussion,
  PlayerAttachment,
  PlayerBlotter,
  PlayerHundred,
  PlayerIntel,
  PlayerJournal,
  PlayerMedia,
  PlayerScouting,
  TeamAttachment,
  TeamIntel,
  TeamMedia
];

const AllSummary = [
  CoachIntel,
  PlayerAthleticTest,
  PlayerBlotter,
  PlayerInjury,
  PlayerIntel,
  PlayerScouting,
  TeamIntel
];

const AllReports = [
  CommentaryScoutSummary,
  CommentaryTrackerReport,
  Document,
  FaxbackReport,
  FourFactorReport,
  IntelTrackerReport,
  ListRolesReport,
  MinPerReport,
  PlayerRolesReport,
  PostgameReport,
  ScoutingReportSummary,
  ScoutIntelReport,
  ScoutTrackerReport,
  SeenByScoutReport
];

const AllPersonnel = [
  Agent,
  Coach,
  Contact,
  Scout
];

const AllGlobal = [
  Agent,
  Coach,
  Contact,
  Player,
  Scout,
  Team
];

export default {

  // Singular Permission references
  read: READ,
  write: WRITE,
  delete: DELETE,

  accessLevels: {
    all: ALL,
    int: INT,
    limited: LIMITED,
    med: MED,
    own: OWN,
    rawo: RAWO,
    spur: SPUR
  },

  UNRESTRICTED: null,

  ADMIN_ONLY: UserAdmin,

  Agent,
  AgentAttachment,
  AgentJournal,
  Analytics,
  AnalyticsGroup,
  aLocs,
  aRoles,
  Clusters,
  DraftModel,
  PerformanceValue,
  Rock,
  Coach,
  CoachAttachment,
  CoachHistory,
  CoachIntel,
  CoachJournal,
  CommentaryEvent,
  CommentaryScoutSummary,
  CommentaryTrackerReport,
  Contact,
  Cyclops,
  Discussion,
  Document,
  FaxbackReport,
  FourFactorReport,
  Game,
  IntelTrackerReport,
  List,
  ListCategory,
  ListRolesReport,
  LookupDiscussionMedium,
  LookupDiscussionType,
  LookupDraftRange,
  LookupEventType,
  LookupIntelCategory,
  LookupIntelMedium,
  LookupIntelRelationship,
  LookupIntelSubject,
  LookupJournalMedium,
  LookupLeague,
  LookupLocs,
  LookupMediaMedium,
  LookupMedium,
  LookupPlayerDesignation,
  LookupRelationship,
  LookupReportMedium,
  MergePlayer,
  MinPerReport,
  Notifications,
  Player,
  PlayerAnalyticalAssessment,
  PlayerAthleticTest,
  PlayerAttachment,
  PlayerBlotter,
  PlayerDna,
  PlayerHundred,
  PlayerInjury,
  PlayerIntel,
  PlayerJournal,
  PlayerMedia,
  PlayerRolesReport,
  PlayerSalary,
  PlayerScouting,
  PlayerShot,
  PlayerStats,
  PlayerSummary,
  PostgameReport,
  Roster,
  RosterProjection,
  Scout,
  ScoutingReportSummary,
  ScoutIntelReport,
  ScoutTrackerReport,
  SeenByScoutReport,
  Team,
  TeamAsset,
  TeamAttachment,
  TeamIntel,
  TeamMedia,
  TeamStanding,
  UserAdmin,
  UserProfile,

  ContractDashboard,
  FileByOtherScouts,
  RoomProjections,
  SalaryBook,
  ScoutingEvent,
  ThreeYearProjections,
  VisualScenarios,

  AgentCommentary,
  AttachmentCommentary,
  CoachCommentary,
  IntelCommentary,
  JournalCommentary,
  MediaCommentary,
  PlayerCommentary,
  TeamCommentary,

  AllCommentary,
  AllContract,
  AllGlobal,
  AllMedical,
  AllPersonnel,
  AllReports,
  AllScouting,
  AllSummary
};
