import qs from 'qs'; // TODO - replace with something smaller
import FileUtils from '@/mixins/utils/File';

const apiEndpoint = process.env.VUE_APP_api;
const api2Endpoint = process.env.VUE_APP_api_2;

export default {
  mixins: [
    FileUtils
  ],

  methods: {
    /**
     * Simple fetch function to get BBOPS CSV files from Azure
     */
    apiGetBbopsCSV(url) {
      // Setup abort function
      const abortController = new AbortController();
      const abortSignal = abortController.signal;

      // Abort name
      const component = this.$options._componentTag; // eslint-disable-line no-underscore-dangle

      // Add call details to servicesInProgress array
      this.$store.dispatch('addServiceInProgress', { abortController, component, name: 'apiGetBbopsCSV' });

      return fetch(url, {
        method: 'GET',
        signal: abortSignal
      })
        .then((result) => {
          if (!result.ok) {
            // Generic error
            let errorMsg = 'Data was not returned';

            // Get reason why not ok
            return result.json().then((r) => {
              // If there is a statusText or message, use that
              if (r.message) errorMsg = r.message;
              else if (result.statusText) errorMsg = result.statusText;

              // If a 403 (Access Denied), don't say anything since pages can have some access denied
              // components and some access allowed components
              if (result.status === 403) {
                return r;
              }

              const error = new Error(errorMsg);

              // Show error to user
              this.$store.dispatch('addAlert', {
                error,
                securityAlert: true, // always show error even if logged off
                text: errorMsg,
                type: 'danger'
              });

              // Finally, throw error
              throw error;
            });
          }

          // Result is ok
          const type = this.getContentType(result);

          // Return json directly
          if (type === 'application/json') return result.json();

          // Evaluate text first, then csv parse that
          // Catches case where response is empty string, "" (e.g. login)
          return result.text()
            .then(text => d3.csvParse(text));
        })
        .catch((err) => {
          // If aborted due to service call overlap, go ahead and resolve positively
          // the aborted service call (otherwise throws errors to the user that are not valid)
          if (err.name === 'AbortError') return Promise.resolve({ data: null });

          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', 'apiGetBbopsCSV');
        });
    },

    /**
     * Function that does all http get calls and returns a promise
     *
     * @param {string} verb - api verb ('GET', 'POST', 'DELETE', etc...)
     * @param {string} name - service name used in logging and progress
     * @param {string} service - api url endpoint definition
     * @param {Object} params - Object with Name/Value pairs for query params.
     * @param {Object} data - Object with Name/Value pairs for body data.
     * @param {Object} headers - Object with Name/Value pairs for additional headers
     * @param {Object} v2 - Uses API v2
     * @returns {Promise} - returns a Fetch Promise
     */
    apiCall(verb, name, service, params, data, headers, v2) {
      const offlineAlert = 'Services are unavailable in offline mode. Please connect to the internet to do anything besides working on commentary';

      // Clear out offline alert
      this.$store.commit('removeSpecificAlert', offlineAlert);

      // Check for offline session and bail to prevent 401 errors causing offline session to timeout
      // TODO - should this go through and only fail if the result doesn't come back? So we can get the
      //    cached data?
      if (!window.navigator.onLine || this.$store.getters['security/isOfflineMode']) {
        // Show error to user
        this.$store.dispatch('addAlert', {
          securityAlert: false,
          text: offlineAlert,
          type: 'warning'
        });

        // If offline, go ahead and resolve positively
        // the service call (otherwise throws errors to the user that are not valid)
        return Promise.resolve({ data: null });
      }

      // Get auth token
      const token = this.$store.getters['security/getAuthTokenFn']();

      // List of services in progress to check for blocking calls
      const servicesInProgress = this.$store.getters.getServicesInProgress;

      // Abort name
      const component = this.$options._componentTag; // eslint-disable-line no-underscore-dangle

      // Steps to take if Logging out
      if (service === '/logout') {
        // Abort any services in progress
        _.each(servicesInProgress, (s) => {
          if (s.abortController) s.abortController.abort();
        });

        // Kill deferred calls
        this.$store.dispatch('removeAllServiceDeferred');

      // Steps to take for all other api calls
      } else {
        // Already existing call, abort old one before continuing
        const existingCall = _.find(servicesInProgress, (sp) => {
          return sp.name === name && sp.component === component;
        });
        if (existingCall && !this.$route.meta.disallowAbort) {
          existingCall.abortController.abort();
        }
      }

      // Setup abort function
      const abortController = new AbortController();
      const abortSignal = abortController.signal;

      // Add call details to servicesInProgress array
      this.$store.dispatch('addServiceInProgress', { abortController, component, name });

      const authToken = _.startsWith(token, 'Bearer') ? token : `Bearer ${token}`;
      const mergedHeaders = _.defaults(headers, {
        Authorization: authToken
      });

      const caller = _.find(this.Services.meta, (v, k) => k === name);
      if (caller && caller.cacheable) {
        mergedHeaders['Cache-Control'] = 'cache';
      }

      let fullUrl = '';
      // Make sure params are serialized for the backend
      if (v2) {
        fullUrl = api2Endpoint + service;
        if (params) fullUrl += `?${this.paramsSerializerV2(params)}`;
      } else {
        fullUrl = apiEndpoint + service;
        if (params) fullUrl += `?${this.paramsSerializer(params)}`;
      }

      return fetch(fullUrl, {
        body: data,
        headers: mergedHeaders,
        method: verb,
        signal: abortSignal
      })
        .then((result) => {
          if (!result.ok) {
            // Generic error
            let errorMsg = 'Data was not returned';

            // Get reason why not ok
            return result.json().then((r) => {
              // If there is a statusText or message, use that
              if (r.message) errorMsg = r.message;
              else if (result.statusText) errorMsg = result.statusText;

              // API v2 will have an array of errors sometimes that gives more details
              if (r.errors) errorMsg = `${errorMsg} - ${JSON.stringify(r.errors)}`;

              // If a 401 (Unauthorized), say so
              const is401 = result.status === 401;
              if (is401) {
                errorMsg = errorMsg || 'You do not have permission to be in this area. Therefore you were logged out for safety purposes. If you feel this was in error, please contact an administrator';
                console.error(`${verb} response to ${service} was a 401. Therefore logging out.`);
              }

              // If a 403 (Access Denied), don't say anything since pages can have some access denied
              // components and some access allowed components
              if (result.status === 403) {
                return r;
              }

              const error = new Error(errorMsg);

              if (result.status === 404) {
                error.status = 404;
                throw error;
              }

              // Show error to user
              this.$store.dispatch('addAlert', {
                error,
                securityAlert: true, // always show error even if logged off
                text: errorMsg,
                type: 'danger'
              });

              // If a 401 (Unauthorized), logout (after store dispatch fired)
              if (is401) {
                this.$router.push({ name: 'CommonLogout' });
              }

              // Finally, throw error
              throw error;
            });
          }

          // Result is ok
          // Evaluate text first, then JSON parse that
          // Catches case where response is empty string, "" (e.g. login)
          return result.text()
            .then(text => (text ? JSON.parse(text) : {}))
            .then(d => _.defaults(result, { data: d }))
            // In case response is empty, pass the result object
            // e.g. Login
            .catch(() => result);
        })
        .catch((err) => {
          // If aborted due to service call overlap, go ahead and resolve positively
          // the aborted service call (otherwise throws errors to the user that are not valid)
          if (err.name === 'AbortError') return Promise.resolve({ data: null });

          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', name);
        });
    },

    /**
     * Function that does all http calls for downloading and returns a promise
     *
     * @param {string} name - service name used in logging and progress
     * @param {string} service - api url endpoint definition
     * @param {Object} params - Object with Name/Value pairs for params
     * @param {string} filename - name to save downloaded file as
     * @returns {Promise} - returns a Fetch Promise
     *
     */
    apiDownload(name, service, params, filename) {
      this.$store.dispatch('addServiceInProgress', { name });

      const token = this.$store.getters['security/getAuthTokenFn']();
      const authToken = _.startsWith(token, 'Bearer') ? token : `Bearer ${token}`;
      const headers = {
        Authorization: authToken
      };

      // NOTE
      // - if in development, need to use Prod apiEndpoint to get this to work
      // - Also need to attach a prod auth token (will throw a 401 error and logout if not)
      let fullUrl = apiEndpoint + service;
      if (params) fullUrl += `?${this.paramsSerializer(params)}`;

      const config = {
        headers,
        method: 'GET',
        name,
        responseType: 'arraybuffer'
      };

      return fetch(fullUrl, config)
        .then((result) => {
          if (!result.ok) {
            // Generic error
            let errorMsg = 'Data was not returned';

            // If there is a statusText, use that as error message
            if (result.statusText) errorMsg = result.statusText;

            // If a 401 (Unauthorized), say so
            if (result.status === 401) {
              errorMsg = 'You do not have permission to be in this area. Therefore you were logged out for safety purposes. If you feel this was in error, please contact an administrator';
              console.error(`Response to ${service} Download was a 401. Therefore logging out.`);
            }

            const error = new Error(errorMsg);

            // If a 401 (Unauthorized), logout (after store dispatch fired)
            if (result.status === 401) {
              // Show error to user
              this.$store.dispatch('addAlert', {
                error,
                securityAlert: true, // always show error even if logged off
                text: errorMsg,
                type: 'danger'
              });

              this.$router.push({ name: 'CommonLogout' });
            }

            // Finally, throw error
            throw error;
          }

          return Promise.resolve(this.extractFile(result, filename));
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', name);
        });
    },

    /**
     * Function that does all http calls for downloading and returns a promise
     * apiDownloadDirect takes only a downloadLink, it does not communicate with the backend
     *
     * @param {string} name - service name used in logging and progress
     * @param {string} downloadLink - url where the file lives
     * @param {string} filename - name to save downloaded file as
     * @returns {Promise} - returns a Fetch Promise
     *
     */
    apiDownloadDirect(name, downloadLink, filename) {
      this.$store.dispatch('addServiceInProgress', { name });

      const config = {
        method: 'GET',
        name,
        responseType: 'arraybuffer'
      };

      return fetch(downloadLink, config)
        .then((result) => {
          if (!result.ok) {
            // Generic error
            let errorMsg = 'Data was not returned';

            // If there is a statusText, use that as error message
            if (result.statusText) errorMsg = result.statusText;

            // If a 401 (Unauthorized), say so
            if (result.status === 401) {
              errorMsg = 'You do not have permission to be in this area. Therefore you were logged out for safety purposes. If you feel this was in error, please contact an administrator';
              console.error(`Response to ${name} Download was a 401. Therefore logging out.`);
            }

            const error = new Error(errorMsg);

            // Show error to user
            this.$store.dispatch('addAlert', {
              error,
              securityAlert: true, // always show error even if logged off
              text: errorMsg,
              type: 'danger'
            });

            // If a 401 (Unauthorized), logout (after store dispatch fired)
            if (result.status === 401) {
              this.$router.push({ name: 'CommonLogout' });
            }

            // Finally, throw error
            throw error;
          }

          return Promise.resolve(this.extractFile(result, filename));
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', name);
        });
    },

    /**
     * Function that does the extend call and returns a promise
     *
     * @returns {Promise} - returns a Fetch Promise
     */
    apiExtend() {
      // List of services in progress
      const servicesInProgress = this.$store.getters.getServicesInProgress;

      // If logout is going on, don't extend
      if (_.some(servicesInProgress, sp => sp.name === 'Logout')) {
        return new Promise();
      }

      // Add to services in progress
      this.$store.dispatch('addServiceInProgress', { name: 'postExtendSession' });

      // Token
      const token = this.$store.getters['security/getAuthTokenFn']();
      const authToken = _.startsWith(token, 'Bearer') ? token : `Bearer ${token}`;
      const headers = {
        Authorization: authToken
      };
      const fullUrl = `${apiEndpoint}/extend-token`;

      return fetch(fullUrl, {
        headers,
        method: 'POST'
      })
        .then((result) => {
          if (!result.ok) {
            // Generic error
            let errorMsg = 'Data was not returned';

            // Get reason why not ok
            return result.json().then((r) => {
              // If there is a statusText or message, use that
              if (r.message) errorMsg = r.message;
              else if (result.statusText) errorMsg = result.statusText;

              // If a 401 (Unauthorized), say so
              const is401 = result.status === 401;
              if (is401) {
                errorMsg = errorMsg || 'You do not have permission to be in this area. Therefore you were logged out for safety purposes. If you feel this was in error, please contact an administrator';
                console.error('POST response to \'extend-token\' was a 401. Therefore logging out.');
              }

              // If a 403 (Access Denied), don't say anything since pages can have some access denied
              // components and some access allowed components
              if (result.status === 403) {
                return r;
              }

              const error = new Error(errorMsg);

              // Show error to user
              this.$store.dispatch('addAlert', {
                error,
                securityAlert: true, // always show error even if logged off
                text: errorMsg,
                type: 'danger'
              });

              // If a 401 (Unauthorized), logout (after store dispatch fired)
              if (is401) {
                this.$router.push({ name: 'CommonLogout' });
              }

              // Finally, throw error
              throw error;
            });
          }

          return result;
        })
        .catch((err) => {
          // If aborted due to service call overlap, go ahead and resolve positively
          // the aborted service call (otherwise throws errors to the user that are not valid)
          if (err.name === 'AbortError') return Promise.resolve({ data: null });

          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', 'postExtendSession');
        });
    },

    /**
     * Worker function that does all http calls for uploading and returns a promise.
     *
     * @param {string} name - service name used in logging and progress
     * @param {string} service - api url endpoint definition
     * @param {Object} [params] - Object with Name/Value pairs for params.
     * @param {Object} [file] - File object to upload
     * @param {Object} v2 - Uses API v2
     * @returns {*}
     */
    apiUpload(name, service, params, file, v2) {
      const token = this.$store.getters['security/getAuthTokenFn']();

      // Setup abort function
      const abortController = new AbortController();
      const abortSignal = abortController.signal;

      // Go ahead and make call
      this.$store.dispatch('addServiceInProgress', { abortController, name });

      const authToken = _.startsWith(token, 'Bearer') ? token : `Bearer ${token}`;
      const headers = {
        Authorization: authToken
      };

      // Make sure params are serialized for the backend
      let fullUrl = '';
      if (v2) {
        fullUrl = api2Endpoint + service;
        if (params) fullUrl += `?${this.paramsSerializerV2(params)}`;
      } else {
        // Make sure params are serialized for the backend
        fullUrl = apiEndpoint + service;
        if (params) fullUrl += `?${this.paramsSerializer(params)}`;
      }

      const fd = new FormData();
      fd.append('file', file);

      const config = {
        headers,
        body: fd,
        method: 'POST',
        name,
        params,
        signal: abortSignal
      };

      return fetch(fullUrl, config)
        .then((result) => {
          if (!result.ok) throw new Error();

          return result.text()
            .then(text => (text ? JSON.parse(text) : {}))
            .then(d => _.defaults(result, { data: d }))
            // In case response is empty, pass the result object
            .catch(() => result);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(err);
        })
        .finally(() => {
          this.$store.dispatch('removeServiceInProgress', name);
        });
    },

    /**
     * Maps FE lingo to BE lingo for commentary types
     * @param Object params
     */
    mapCommentaryType(params) {
      const newParams = _.clone(params);
      switch (newParams.commentaryTypeEquals) {
        case 'attachment':
        case 'attachments':
        case 'ATTACHMENT':
          newParams.commentaryTypeEquals = 'ATTACHMENT';
          break;
        case 'blotter':
        case 'blotters':
        case 'BLOTTER':
          newParams.commentaryTypeEquals = 'BLOTTER';
          break;
        case 'discussion':
        case 'discussions':
        case 'DISCUSSION':
          newParams.commentaryTypeEquals = 'DISCUSSION';
          break;
        case 'intel-reports':
        case 'intel':
        case 'INTEL':
          newParams.commentaryTypeEquals = 'INTEL';
          break;
        case 'journal':
        case 'journals':
        case 'JOURNAL':
          newParams.commentaryTypeEquals = 'JOURNAL';
          break;
        case 'media-report':
        case 'media-reports':
        case 'MEDIA':
          newParams.commentaryTypeEquals = 'MEDIA';
          break;
        case 'scouting-reports':
        case 'scouting':
        case 'REPORT':
          newParams.commentaryTypeEquals = 'REPORT';
          break;
        case 'spurs-hundred':
        case 'spurs-hundreds':
        case 'HUNDRED':
          newParams.commentaryTypeEquals = 'HUNDRED';
          break;
        default:
          delete newParams.commentaryTypeEquals;
      }

      return newParams;
    },

    /**
     * Maps FE lingo to BE lingo for list colors
     * @param Object params
     */
    mapListColors(data, hexToInt) {
      if (!data) return data;

      _.each(data.preflistPlayers || data.players, (p) => {
        if (hexToInt) {
          if (p.backColor === 'transparent') p.backColor = null;
          else if (p.backColor) p.backColor = parseInt(p.backColor.slice(1), 16);

          if (p.foreColor === '#000') p.foreColor = null;
          else if (p.foreColor) p.foreColor = parseInt(p.foreColor.slice(1), 16);
        } else {
          if (p.backColor === 16777215) p.backColor = 'transparent';
          else if (p.backColor || p.backColor === 0) p.backColor = `#${(`00000${Number(p.backColor).toString(16)}`).slice(-6)}`;

          if (p.foreColor) p.foreColor = `#${(`00000${Number(p.foreColor).toString(16)}`).slice(-6)}`;
          else p.foreColor = '#000';
        }
      });

      return data;
    },

    /**
     * Maps FE lingo to BE lingo for medical types
     * @param Object params
     */
    mapMedicalType(params) {
      const newParams = _.clone(params);
      switch (newParams.commentaryTypeEquals) {
        case 'athletics':
        case 'ATHLETIC':
          newParams.commentaryTypeEquals = 'ATHLETIC';
          break;
        case 'injury-reports':
        case 'injury':
        case 'INJURY':
          newParams.commentaryTypeEquals = 'INJURY';
          break;
        default:
          delete newParams.commentaryTypeEquals;
      }

      return newParams;
    },

    /**
     * Serializes params in form for the backend
     */
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },

    /**
     * Serializes params in form for the v2 backend
     */
    paramsSerializerV2(params) {
      const localParams = _.cloneDeep(params);
      const query = _.cloneDeep(params.query);
      delete localParams.query;

      let retString = _.map(localParams, (v, k) => `${k}=${v}`);
      if (query?.$and?.length) retString.push(`query=${JSON.stringify(query)}`);
      if (query?.$or?.length) retString.push(`&query=${JSON.stringify(query)}`);
      retString = _.join(retString, '&');

      return retString;
    },

    /**
     * Utility function to set paging query params for services that accept them.
     *
     * @param params - query params object to add paging to
     * @param page - page to request. def = 0
     * @param limit - page limit to request. def = 100
     * @param sort - optional sort column
     * @param direction - optional sort dir. def = desc
     * @returns {*|{}} - modified params object
     */
    setPage(params, page, limit, sort, direction) {
      params = _.cloneDeep(params) || {};

      if (params.page === 0 || page === 0) params.page = 0;
      else params.page = params.page || page || 1;

      params.limit = params.limit || limit || 100;

      if (sort) {
        params.sort = sort;
        params.sortDirection = direction || 'desc';
      }

      return params;
    }
  }
};
