import CONSTANTS from '@/store/Constants';
import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Player Wrapper
  {
    path: '/player/',
    name: 'PlayerRoot',
    props: true,
    meta: {
      defaultId: CONSTANTS.defaults.playerId,
      idName: 'playerId',
      permission: PERMISSIONS.read,
      primary: 'player',
      securable: PERMISSIONS.Player
    },
    component: () => import('@/views/player/PlayerRoot.vue'),

    // Children Routes
    children: [
      {
        path: '/player/overview/:playerId?',
        name: 'PlayerOverview',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Overview',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/PlayerOverview.vue')
      },
      {
        path: '/player/analytic-assessment/:playerId?',
        name: 'PlayerAnalyticAssessment',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Analytical Assessment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        },
        component: () => import('@/views/player/PlayerAnalyticAssessment.vue')
      },
      {
        path: '/player/bio/:playerId?',
        name: 'PlayerBio',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Bio',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/PlayerBio.vue')
      },
      {
        path: '/player/commentary/:playerId?',
        name: 'PlayerCommentary',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Commentary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerCommentary
        },
        component: () => import('@/views/player/PlayerCommentary.vue')
      },
      {
        path: '/player/dna/:playerId?',
        name: 'PlayerDna',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'DNA',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerDna
        },
        component: () => import('@/views/player/PlayerDna.vue')
      },
      {
        path: '/player/draft-model/:playerId?/:query?',
        name: 'PlayerDraftModel',
        props: true,
        meta: {
          pageInSearch: true,
          disallowAbort: true,
          pageTitle: 'Draft Model',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        },
        component: () => import('@/views/player/PlayerDraftModel.vue')
      },
      {
        path: '/player/game-log/:playerId?',
        name: 'PlayerGameLog',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Game Log',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        },
        component: () => import('@/views/player/PlayerGameLog.vue')
      },
      {
        path: '/player/lists/:playerId?',
        name: 'PlayerLists',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Lists',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        },
        component: () => import('@/views/player/PlayerLists.vue')
      },
      {
        path: '/player/medical/:playerId?',
        name: 'PlayerMedical',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Medical',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        },
        component: () => import('@/views/player/PlayerMedical.vue')
      },
      {
        path: '/player/measurements/:playerId?',
        name: 'PlayerMeasurements',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Measurements',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/PlayerMeasurements.vue')
      },
      {
        path: '/player/shot-chart/:playerId?',
        name: 'PlayerShotChart',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Shot Chart',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        },
        component: () => import('@/views/player/PlayerShotChart.vue')
      },
      {
        path: '/player/stats/:playerId?',
        name: 'PlayerStats',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Stats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        },
        component: () => import('@/views/player/PlayerStats.vue')
      },
      {
        path: '/player/summary/:playerId?',
        name: 'PlayerSummary',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Summary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        },
        component: () => import('@/views/player/PlayerSummary.vue')
      },

      /**
       *  Player Edit Pages
       */
      {
        path: '/player/edit-bio/:playerId?',
        name: 'PlayerEditBio',
        props: true,
        meta: {
          allowBlankId: true,
          pageTitle: 'Edit Bio',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/edit/PlayerEditBio.vue')
      },
      {
        path: '/player/edit-contacts/:playerId?',
        name: 'PlayerEditContacts',
        props: true,
        meta: {
          disallowAbort: true,
          pageTitle: 'Edit Contact',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        },
        component: () => import('@/views/player/edit/PlayerEditContacts.vue')
      },
      {
        path: '/player/edit-dna/:playerId?',
        name: 'PlayerEditDna',
        props: true,
        meta: {
          pageTitle: 'Edit Dna',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/edit/PlayerEditDna.vue')
      },
      {
        path: '/player/edit-headshot/:playerId?',
        name: 'PlayerEditHeadshot',
        props: true,
        meta: {
          pageTitle: 'Edit Player Headshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/edit/PlayerEditHeadshot.vue')
      },
      {
        path: '/player/edit-history/:playerId?',
        name: 'PlayerEditHistory',
        props: true,
        meta: {
          pageTitle: 'Edit Playing History',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        },
        component: () => import('@/views/player/edit/PlayerEditHistory.vue')
      },
      {
        path: '/player/edit-summary/:playerId?',
        name: 'PlayerEditSummary',
        props: true,
        meta: {
          pageTitle: 'Edit Player Summary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerSummary
        },
        component: () => import('@/views/player/edit/PlayerEditSummary.vue')
      },

      // Default routes
      {
        path: '/player/*',
        redirect: '/player/overview'
      },
      {
        path: '/player',
        redirect: '/player/overview'
      }
    ]
  }
];
