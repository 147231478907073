/**
 * Handles all Security/Authentication events
 *  - Login
 *  - Logout
 *  - Verify
 */

const config = {
  authorizationHeader: 'Authorization',
  headerPrefix: 'Bearer',

  tokenIssuer: 'san-antonio-spurs',

  authTokenStorage: 'san-antonio-spurs-jwt',
  authTokenSetDateStorage: 'san-antonio-spurs-jwt-date',
  usernameStorage: 'san-antonio-spurs-un',

  verificationTokenStorage: 'san-antonio-spurs-verification-jwt',
  verificationHeader: 'Verification',
  verificationRole: 'ROLE_PRE_AUTHORIZED'
};

/**
 * Derive Token from Server Response
 *
 * @param serviceResult
 * @param {string} tokenHeader
 * @returns {object}
 */
function getTokenFromResponse(serviceResult, tokenHeader) {
  const lowerHeader = tokenHeader.toLowerCase();

  // Get header whether a fetch or xhr request
  let header = null;
  if (serviceResult.headers) {
    if (_.isFunction(serviceResult.headers.get)) {
      header = serviceResult.headers.get(lowerHeader);
    } else {
      header = serviceResult.headers[lowerHeader];
    }
  } else if (serviceResult.message) {
    throw new Error(serviceResult.message);
  }

  if (header === null) {
    throw new Error(`Auth Error : No ${tokenHeader} header was found in response`);
  }

  if (config.headerPrefix) {
    // Split header on space between scheme and token
    const parts = header.split(' ');

    // Ensure that two parts (scheme and token) were present
    if (parts.length !== 2) {
      throw new Error(`Auth Error : Header Format invalid, should be ${tokenHeader}: ${config.headerPrefix} [token]`);
    }

    const scheme = parts[0];
    const credentials = parts[1];

    // Only return token if scheme matches configured headerPrefix
    return scheme === config.headerPrefix ? credentials : null;
  }

  return null;
}

/**
 * Decode JWT tokens
 *
 * @param token
 * @param {string} claim (optional)
 * @returns {object}
 */
function decodeToken(token, claim) {
  if (!token) return null;
  const parts = token.split('.');
  const decoded = JSON.parse(window.atob(parts[1]));

  if (claim) return decoded[claim];

  return decoded;
}

/**
 * Checks expiration date of Token
 *
 * @param token
 * @returns {boolean}
 */
function isTokenExpired(token) {
  const expirationDateMillis = new Date(0).setUTCSeconds(decodeToken(token, 'exp'));
  const currentDate = Date.now();
  return expirationDateMillis <= currentDate;
}

/**
 * Checks users roles for any of the passed in roles and returns true
 * if any one exists. role param can be a string or array of strings.
 *
 * @param {string} token
 * @param {string} roleRequested
 * @returns {boolean}
 */
function hasRole(token, roleRequested) {
  const rolesPossessed = decodeToken(token, 'roles');

  // bail early if no token found or no roles possessed
  if (!token || !rolesPossessed) return false;

  // return true immediately if roles requested is empty
  if (!roleRequested || !_.isString(roleRequested) || roleRequested === '') return true;

  // If rolesPossessed is a string, return the match
  if (_.isString(rolesPossessed)) return rolesPossessed === roleRequested;

  // Else if rolesPossessed is an array, return whether roleRequested is in the array
  if (_.isArray(rolesPossessed)) return _.some(rolesPossessed, rp => rp === roleRequested);

  // default - role not found
  return false;
}

/**
 * Generate Verification Storage Location from given username
 *
 * @param {string} username
 */
function getStorageLocation(type, username) {
  const configType = `${type}Storage`;
  if (username) return `${config[configType]}:${username.toLowerCase()}`;

  return `${config[configType]}`;
}

/**
 * Gets the current auth token. This is not stored in state
 * as state is not propagated through multiple tabs/windows
 */
function getAuthTokenFromStorage(username) {
  const authTokenLocation = getStorageLocation('authToken', username);
  const authToken = localStorage.getItem(authTokenLocation);
  if (authToken === 'null') return null;
  return localStorage.getItem(authTokenLocation) || null;
}

function getAuthTokenSetDateFromStorage(username) {
  const authTokenSetDateLocation = getStorageLocation('authTokenSetDate', username);
  const authTokenSetDate = localStorage.getItem(authTokenSetDateLocation);
  if (authTokenSetDate === '0') return 0;
  return +authTokenSetDate || null;
}

export default {
  namespaced: true,

  // Actual State stored
  state: {
    isPrint: false,
    offlineMode: false,
    preLoginPath: null,
    username: null,
    verificationToken: null
  },

  // Mutations that directly affect state - SYNCHRONOUS
  mutations: {
    // Initialise Security from localStorage keys if available
    initialise(state) {
      state.username = localStorage.getItem(config.usernameStorage) || null;

      if (state.username) {
        const verificationTokenLocation = getStorageLocation('verificationToken', state.username);
        state.verificationToken = localStorage.getItem(verificationTokenLocation) || null;
      } else {
        state.verificationToken = null;
      }
    },

    // Authentication
    updateAuthToken(state, newToken) {
      const authTokenLocation = getStorageLocation('authToken', state.username);
      localStorage.setItem(authTokenLocation, newToken);

      // Update auth date token
      if (newToken) this.commit('security/updateAuthTokenSetDate', Date.now());
      else this.commit('security/updateAuthTokenSetDate', null);
    },

    removeAuthToken(state) {
      const authTokenLocation = getStorageLocation('authToken', state.username);
      localStorage.removeItem(authTokenLocation);
      const authTokenSetDateLocation = getStorageLocation('authTokenSetDate', state.username);
      localStorage.removeItem(authTokenSetDateLocation);
    },

    // Update Auth Token Set Date
    updateAuthTokenSetDate(state, newDateToken) {
      const authTokenSetDateLocation = getStorageLocation('authTokenSetDate', state.username);
      localStorage.setItem(authTokenSetDateLocation, newDateToken);
    },

    updateIsPrint(state, newValue) {
      state.isPrint = newValue;
    },

    updateUsername(state, newUsername) {
      localStorage.setItem(config.usernameStorage, newUsername);
      state.username = newUsername;
      this.commit('security/updateVerificationTokenFromStorage');
    },

    updateUsernameFromAuthToken(state, authToken) {
      const username = _.toLower(decodeToken(authToken, 'sub'));
      localStorage.setItem(config.usernameStorage, username);
      state.username = username;
    },

    removeUsername(state) {
      localStorage.removeItem(config.usernameStorage);
      state.username = null;
    },

    updateOfflineMode(state, value) {
      state.offlineMode = value;
    },

    // Pre Login Path for return after login
    updatePreLoginPath(state, newPath) {
      state.preLoginPath = newPath;
    },

    // Verification
    updateVerificationTokenFromStorage(state) {
      const verificationTokenLocation = getStorageLocation('verificationToken', state.username);
      const newToken = localStorage.getItem(verificationTokenLocation);
      if (newToken) this.commit('security/updateVerificationToken', newToken);
    },

    updateVerificationToken(state, newToken) {
      const verificationTokenLocation = getStorageLocation('verificationToken', state.username);
      localStorage.setItem(verificationTokenLocation, newToken);
      state.verificationToken = newToken;
    },

    removeVerificationToken(state) {
      const verificationTokenLocation = getStorageLocation('verificationToken', state.username);
      localStorage.removeItem(verificationTokenLocation);
      state.verificationToken = null;
    }
  },

  // Various actions that affect state, should call mutations and not set state directly - ASYNC
  actions: {
    login({ state }, vars) {
      let { token } = vars;
      const { serverResponse, username } = vars;

      if (!token && !serverResponse) throw new Error('No server response. Unable to login. Contact administrator');
      else if (!token) token = getTokenFromResponse(serverResponse, config.authorizationHeader);

      // Don't use token if not from the correct issuer
      if (decodeToken(token, 'iss') !== config.tokenIssuer) {
        throw new Error('Login returned a token from an invalid issuer');
      }

      // Username must be set here to allow auth and verify to function
      this.commit('security/updateUsername', username);

      // Token must be set here to allow hasRole and verify to function
      this.commit('security/updateAuthToken', token);

      // We check for verificationRole in new token to determine if verification is required
      const verificationRequired = hasRole(token, config.verificationRole);

      let path = '/';
      // If required, then clear verification token, and set path as Verify
      if (verificationRequired) {
        this.commit('security/removeVerificationToken');
        path = '/verify';

      // Else if preLoginPath, set path to that
      } else if (state.preLoginPath && state.preLoginPath !== '/login') {
        path = state.preLoginPath;

      // Else set path to Dashboard
      } else path = '/';

      return path;
    },

    setAuthToken({ commit }, token) {
      if (token) commit('updateAuthToken', token);
      else commit('removeAuthToken');
    },

    setOfflineMode({ commit }, value) {
      if (value) commit('updateOfflineMode', true);
      else commit('updateOfflineMode', false);
    },

    setUsername({ commit }, username) {
      if (username) commit('updateUsername', username);
      else commit('removeUsername');
    },

    // Logout
    logout({ commit }) {
      this.commit('permissions/removePermissions'); // this refers to store, not store/Security
      commit('removeAuthToken');
      commit('removeUsername');
    },

    // Extend Session
    extendSession({ dispatch }, serverResponse) {
      const newAuthToken = getTokenFromResponse(serverResponse, config.authorizationHeader);
      dispatch('setAuthToken', newAuthToken);
    },

    // Verification
    verify(state, serverResponse) {
      const verificationToken = getTokenFromResponse(serverResponse, config.verificationHeader);

      // Don't use token if not from the correct issuer
      if (decodeToken(verificationToken, 'iss') !== config.tokenIssuer) {
        throw new Error('Verify returned a token from an invalid issuer');
      }

      this.commit('security/updateVerificationToken', verificationToken);
    },

    resetVerificationToken({ commit }) {
      commit('removeVerificationToken');
    }
  },

  // Computed or helper Getters
  // A lot of these have an extra fn call so that they aren't cached based on username
  //  and are instead checked each time they are called
  // Wherever the `getUsernameFromStorage` is called, this is because we can't rely on
  //  the username that is "stored" in the store (due to logged out, etc...)
  getters: {
    // Username
    getUsername: state => state.username,
    getUsernameFromStorage: () => () => localStorage.getItem(config.usernameStorage),

    // Authentication
    getAuthorizationHeader: ({ username }) => {
      const authToken = getAuthTokenFromStorage(username);

      if (authToken) {
        return `${config.headerPrefix} ${authToken}`;
      }

      return null;
    },
    getAuthTokenFn: (state, getters) => () => getAuthTokenFromStorage(getters.getUsernameFromStorage()),
    getAuthTokenSetDateFn: (state, getters) => () => getAuthTokenSetDateFromStorage(getters.getUsernameFromStorage()),
    getAuthTokenExpiryFn: (state, getters) => () => {
      // Expiry is stored missing the final 3 0s. Need to add them manually
      const exp = decodeToken(getAuthTokenFromStorage(getters.getUsernameFromStorage()), 'exp');
      return exp ? +(`${exp.toString()}000`) : null;
    },
    getPreLoginPath: state => state.preLoginPath,

    isLoggedIn: ({ username }) => () => {
      const authToken = getAuthTokenFromStorage(username);

      // Bail early if no token found locally
      if (!authToken) return false;

      // Don't use token if not from the correct issuer
      if (decodeToken(authToken, 'iss') !== config.tokenIssuer) return false;

      // Don't use token if its expired
      if (isTokenExpired(authToken)) return false;

      return true;
    },
    isOfflineMode: state => state.offlineMode,

    // Verification
    getVerificationHeader: ({ verificationToken }) => {
      if (verificationToken) {
        return `${config.headerPrefix} ${verificationToken}`;
      }

      return null;
    },

    isVerificationRequired: (state, getters) => {
      return getters.isVerificationRequiredForGivenUser(state.username);
    },
    isVerificationRequiredForGivenUser: state => (user) => {
      const authToken = getAuthTokenFromStorage(user);

      // Check that verification is even needed
      const needVerification = hasRole(authToken, config.verificationRole);
      return needVerification && state.verificationToken === null;
    },

    isPrint: state => state.isPrint
  }
};
