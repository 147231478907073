import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store';

// Load Route Files
import AdminRoutes from '@/routes/AdminRoutes';
import AnalyticsRoutes from '@/routes/AnalyticsRoutes';
import CoachRoutes from '@/routes/CoachRoutes';
import CommentaryRoutes from '@/routes/CommentaryRoutes';
import ContractsRoutes from '@/routes/ContractsRoutes';
import IntelRoutes from '@/routes/IntelRoutes';
import ListsRoutes from '@/routes/ListsRoutes';
import MainRoutes from '@/routes/MainRoutes';
import MedicalRoutes from '@/routes/MedicalRoutes';
import PersonnelRoutes from '@/routes/PersonnelRoutes';
import PlayerRoutes from '@/routes/PlayerRoutes';
import ReportsRoutes from '@/routes/ReportsRoutes';
import SchedulesRoutes from '@/routes/SchedulesRoutes';
import ScoutingRoutes from '@/routes/ScoutingRoutes';
import TeamRoutes from '@/routes/TeamRoutes';
import VideoRoutes from '@/routes/VideoRoutes';

/**
 * Add error checking for push/replace methods
 * See https://github.com/vuejs/vue-router/issues/2881 for why
 */
const routerMethods = ['push', 'replace'];
routerMethods.forEach((method) => {
  const originalCall = Router.prototype[method];
  Router.prototype[method] = function routerError(location, onResolve, onReject) {
    if (onResolve || onReject) return originalCall.call(this, location, onResolve, onReject);
    return originalCall.call(this, location)
      .catch((err) => {
        console.error(err);
        console.trace();
        return err;
      });
  };
});

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'vue-router-active',
  linkExactActiveClass: 'vue-router-exact-active'
});

/**
 * Add Routes
 * Concat all page routes under the Main children route
 */
const mainRoute = _.find(MainRoutes, mr => mr.routeName === 'MainRoot');
mainRoute.children = mainRoute.children.concat(
  AdminRoutes,
  AnalyticsRoutes,
  CoachRoutes,
  CommentaryRoutes,
  ContractsRoutes,
  IntelRoutes,
  ListsRoutes,
  MedicalRoutes,
  PersonnelRoutes,
  PlayerRoutes,
  ReportsRoutes,
  SchedulesRoutes,
  ScoutingRoutes,
  TeamRoutes,
  VideoRoutes
);

_.each(MainRoutes, (mr) => {
  router.addRoute(mr);
});

/**
 * Helper function to Return to Dashboard
 */
function navigateToDashboard(to, next) {
  Store.commit('setNavState', {
    name: 'CommonDashboard',
    params: {},
    query: {}
  });

  // Check to prevent infinite looping
  if (to.fullPath === '/') next();
  else next('/');
}

/**
 * Helper function to generate any missing Ids
 */
function generateNavState(to) {
  // Combine meta information from each level of route
  const combinedMeta = _.reduce(to.matched, (memo, m) => _.assign(memo, m.meta), {});

  const newNavState = {
    name: to.name,
    params: to.params,
    query: to.query
  };

  // Couple of things to do if id required
  if (combinedMeta.idName && !combinedMeta.allowBlankId) {
    let id = to.params[combinedMeta.idName];

    // No default id provided
    if (!id) {
      const navState = Store.getters.getNavState;
      id = navState[combinedMeta.idName] || combinedMeta.defaultId;
      newNavState.replace = true;
    }

    newNavState[combinedMeta.idName] = id;
    newNavState.params[combinedMeta.idName] = id;
  }

  return newNavState;
}

/**
 * Route check
 * Various checks to run before following each route
 */
router.beforeEach((to, from, next) => {
  console.group('to:', decodeURIComponent(to.fullPath));
  console.info('from', decodeURIComponent(from.fullPath));
  console.info('to', decodeURIComponent(to.fullPath));
  console.info('loggedIn', Store.getters['security/isLoggedIn']());
  console.info('verified', !Store.getters['security/isVerificationRequired']);
  console.groupCollapsed();
  console.log('authToken', Store.getters['security/getAuthTokenFn']());
  console.trace();
  console.groupEnd();
  console.groupEnd();

  // Blank out nav state
  Store.commit('setNavState', {
    name: 'CommonDashboard',
    params: {},
    query: {}
  });

  // Remove any alerts that are not security specific
  Store.dispatch('removeAlert');

  // Allow Navigating to Login/Verify/Logout/Settings paths
  if (to.name === 'CommonLogin'
    || to.name === 'CommonVerify'
    || to.name === 'CommonLogout'
  ) next();

  // Allow if navigating externally in general (e.g. Bbops site)
  else if (to.meta && to.meta.externalPath) window.location = to.meta.externalPath;

  // Allow if offline (no data can be fetched, and permissions take care of the rest)
  else if (!window.navigator.onLine) next();

  // Allow if auth token attached (validity then determined on data requests)
  // Only used for printing
  else if (to.query.Authorization) {
    // Allow other parts of the app to see the username and auth token, not just the URL
    Store.commit('security/updateUsernameFromAuthToken', to.query.Authorization);
    Store.commit('security/updateAuthToken', to.query.Authorization);
    Store.commit('security/updateIsPrint', true); // used to prevent some ExtendSession bugs

    // Permissions are fetched and stored in App.vue so they can use the standard Services mixins
    // This is so that v-show-if-service-permission works

    // Generate any missing ids
    const newNavState = generateNavState(to);

    // Set new nav state
    Store.commit('setNavState', newNavState);

    next();

  // Not logged in
  } else if (!Store.getters['security/isLoggedIn']()) {
    // If navigating from / to / and not logged in,
    // then probably first load so just go to login without error
    if (to.fullPath === '/' && from.fullPath === '/') next('/login');

    // Else kick out to login page WITH error
    // (store preLoginPath for return trip after login)
    else {
      Store.commit('security/updatePreLoginPath', to.fullPath);
      Store.dispatch('addAlert', {
        securityAlert: true,
        text: 'Please login',
        type: 'warning'
      });
      next('/login');
    }

  // Not verified (store preLoginPath for return trip after login and verification)
  } else if (Store.getters['security/isVerificationRequired']) {
    Store.commit('security/updatePreLoginPath', to.fullPath);
    Store.dispatch('addAlert', {
      securityAlert: true,
      text: 'Not verified. Please login and an email will be sent with a new verification code.',
      type: 'danger'
    });
    next('/login');

  // Allow navigation to Dashboard
  } else if (to.name === 'CommonDashboard') navigateToDashboard(to, next);

  // Allow nav to Settings/User Profile page
  else if (to.name === 'CommonSettings') next();
  else if (to.name === 'CommonEditProfile') next();

  // Internal navigation required
  else {
    // Generate any missing ids
    const newNavState = generateNavState(to);

    // Set new nav state
    Store.commit('setNavState', newNavState);

    // Check to make sure this is a different query and so prevent infinite loops
    const differentQuery = _.isEqual(to.name, from.name) && _.isEqual(newNavState.params, from.params);

    // Check for route Permissions
    // If no meta or no securable defined, continue on
    if (!to.meta || !to.meta.securable) {
      console.info('permission to view page:', true, ', no meta or no securable');

      // If only thing that changed was query, only update that,
      // If id was replaced, nav to new id
      // Prevents infinite routing loop
      if (differentQuery) next({ query: to.query });
      else if (newNavState.replace) next(newNavState);
      else next();

    // Else if securable is defined, check user permission to access route
    } else if (to.meta && to.meta.securable && to.meta.permission
        && Store.getters['permissions/hasPermission'](to.meta.securable, to.meta.permission)) {
      console.info('permission to view page', Store.getters['permissions/hasPermission'](to.meta.securable, to.meta.permission));

      // If only thing that changed was query, only update that
      // If id was replaced, nav to new id
      // Prevents infinite routing loop
      if (differentQuery) next({ query: to.query });
      else if (newNavState.replace) next(newNavState);
      else next();

    // Else return to Dashboard
    } else {
      Store.dispatch('addAlert', {
        error: new Error('Wrong permissions'),
        securityAlert: true,
        text: 'You do not have permission to access this page. Please contact the administrator if you need to view this page.',
        type: 'danger'
      });
      navigateToDashboard(to, next);
    }
  }
});

export default router;
