import CONSTANTS from '@/store/Constants';
import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Team Wrapper
  {
    path: '/team/',
    name: 'TeamRoot',
    props: true,
    component: () => import('@/views/team/TeamRoot.vue'),
    meta: {
      defaultId: CONSTANTS.defaults.teamId,
      idName: 'teamId',
      permission: PERMISSIONS.read,
      primary: 'team',
      securable: PERMISSIONS.Team
    },

    // Children Routes
    children: [
      {
        path: '/team/overview/:teamId?',
        name: 'TeamOverview',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Overview',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Team
        },
        component: () => import('@/views/team/TeamOverview.vue')
      },
      {
        path: '/team/schedule/:teamId?/:season?',
        name: 'TeamSchedule',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Schedule',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        },
        component: () => import('@/views/team/TeamSchedule.vue')
      },
      {
        path: '/team/roster/:teamId?',
        name: 'TeamRoster',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Roster',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Roster
        },
        component: () => import('@/views/team/TeamRoster.vue')
      },
      {
        path: '/team/depth-chart/:teamId?',
        name: 'TeamDepthChart',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Depth Chart',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Roster
        },
        component: () => import('@/views/team/TeamDepthChart.vue')
      },
      {
        path: '/team/dna/:teamId?',
        name: 'TeamDna',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'DNA',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerDna
        },
        component: () => import('@/views/team/TeamDna.vue')
      },
      {
        path: '/team/assets/:teamId?',
        name: 'TeamAssets',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Assets',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamAsset
        },
        component: () => import('@/views/team/TeamAssets.vue')
      },
      {
        path: '/team/player-stats/:teamId?',
        name: 'TeamPlayerStats',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Team Player Stats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        },
        component: () => import('@/views/team/TeamPlayerStats.vue')
      },
      {
        path: '/team/commentary/:teamId?',
        name: 'TeamCommentary',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Commentary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamCommentary
        },
        component: () => import('@/views/team/TeamCommentary.vue')
      },
      {
        path: '/team/shot-charts/:teamId?',
        name: 'TeamShotCharts',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Shot Charts',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        },
        component: () => import('@/views/team/TeamShotCharts.vue')
      },

      // Edit
      {
        path: '/team/edit-logo/:teamId?',
        name: 'TeamLogoEdit',
        props: true,
        meta: {
          allowBlankId: true,
          pageTitle: 'Edit Team Logo',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Team
        },
        component: () => import('@/views/team/edit/TeamLogoEdit.vue')
      },
      {
        path: '/team/edit-overview/:teamId?',
        name: 'TeamOverviewEdit',
        props: true,
        meta: {
          allowBlankId: true,
          pageTitle: 'Edit Team',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Team
        },
        component: () => import('@/views/team/edit/TeamOverviewEdit.vue')
      },
      {
        path: '/team/edit-depth/:teamId?',
        name: 'TeamDepthEdit',
        props: true,
        meta: {
          allowBlankId: true,
          pageTitle: 'Edit Team Depth',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Team
        },
        component: () => import('@/views/team/edit/TeamDepthEdit.vue')
      },

      // Default routes
      {
        path: '/team/*',
        redirect: '/team/overview'
      },
      {
        path: '/team',
        redirect: '/team/overview'
      }
    ]
  }
];
